import { render, staticRenderFns } from "./ListCreativeAffiliate.vue?vue&type=template&id=36f5294a"
import script from "./ListCreativeAffiliate.vue?vue&type=script&lang=js"
export * from "./ListCreativeAffiliate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QIcon,QCard,QCardSection,QImg,QAvatar,QList,QItem,QItemSection,QItemLabel,QTooltip,QBtn,QToolbar,QSpace,QPagination});
