<template>
  <q-page padding>
    <q-card flat>
      <q-toolbar class="column">
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Meus Criativos</q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
          <q-breadcrumbs-el label="Meus Criativos" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />
      <q-card-section class="row q-col-gutter-md">
        <div :class="pageStart ? 'col-12 col-xs-12 col-sm-12 col-md-12' : 'col-3 col-xs-12 col-sm-12 col-md-3'">
          <filter-creative-affiliate @showPreFilter="formatFilters" class="position" />
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-12">
          <list-creative-affiliate />
        </div>
      </q-card-section>
    </q-card>
    <edit-creative :id="idCreative" />
    <TagCreativeAffiliate :id="idCreativeTag" :channel="idChannel" />
  </q-page>
</template>

<script>
import EditCreative from '../../components/creative/EditCreative.vue';
import FilterCreativeAffiliate from '../../components/creative/FilterCreativeAffiliate.vue';
import ListCreativeAffiliate from '../../components/creative/ListCreativeAffiliate.vue';
import CreativeMixin from '../../mixins/CreativeMixin';
import CreativeService from '../../services/CreativesService';
import TagCreativeAffiliate from '../../components/creative/TagCreativeAffiliate';

export default {
  name: 'PageCreative',
  data() {
    return {
      pageStart: false,
      dialog: false
    };
  },
  mixins: [CreativeMixin, CreativeService],
  props: {
    idCreative: {
      type: [Number, String],
      default: null
    },
    idCreativeTag: {
      type: [Number, String],
      default: null
    },
    idChannel: {
      type: [Number, String],
      default: null
    }
  },
  components: {
    ListCreativeAffiliate,
    EditCreative,
    TagCreativeAffiliate,
    FilterCreativeAffiliate
  },
  methods: {
    formatFilters(preFilter) {
      // console.log('preFilter ', preFilter)
      this.pageStart = preFilter;
    }
  },
  created() {
    this.formatFilters(true);
  }
};
</script>
