<template>
  <div>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }}</q-toolbar-title> -->
      </q-toolbar>
      <q-form ref="form" @submit="onFilterCreative">
        <q-card-section v-if="pageStart" class="q-pt-none row q-col-gutter-md">
          <div class="col-4">
            <q-select
              v-model="channel"
              label="Selecione o canal"
              outlined
              use-input
              input-debounce="200"
              @filter="filterChannel"
              :options="channelsFiltered"
              option-label="name"
              option-value="id"
              clearable
              :rules="[isRequired]"
              @input="getCampaignRelated"
            />
          </div>
          <div class="col-4">
            <q-select
              v-model="campaignSelected"
              use-input
              input-debounce="200"
              @filter="filterCampaignSelected"
              :options="campaignsFiltered"
              :label="
                campaigns.length === 0 ? 'Não existem campanhas autorizadas a esse canal' : 'Selecione a campanha'
              "
              :disable="disable"
              outlined
              option-label="campaign_name"
              option-value="campaign_id"
              clear-icon="mdi-close"
              clearable
            />
          </div>
          <div class="col-4">
            <div class="col-12">
              <select-request
                :v-model="params.type"
                outlined
                :label="$t('type')"
                endpoint="redirect?app=CREATIVE&path=/api/get/creative_type"
                option-label="description"
                option-value="id"
                @update="(value) => (params.type = value)"
              />
            </div>
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <!--q-btn
                            color="positive"
                            :label="$t('search')"
                            no-caps
                            unelevated
                            class="q-px-md q-my-sm"
                            :disable="!this.channel"
                            type="submit"
                        /-->
          </div>
        </q-card-section>
        <!--
                <q-card-section v-else class="q-pt-none row q-col-gutter-md">
                    <div class="col-12">
                        <q-select
                            v-model="channel"
                            label="Selecione o canal"
                            outlined
                            use-input
                            input-debounce="200"
                            @filter="filterChannel"
                            :options="channelsFiltered"
                            option-label="name"
                            option-value="id"
                            clearable
                            @input="getCampaignRelated"
                        />
                    </div>
                    <div class="col-12">
                        <q-select
                            v-model="campaignSelected"
                            use-input
                            input-debounce="200"
                            @filter="filterCampaignSelected"
                            :options="campaignsFiltered"
                            :label="
                campaigns.length === 0
                  ? 'Não existem campanhas autorizadas a esse canal'
                  : 'Selecione a campanha'
              "
                            :disable="disable"
                            outlined
                            option-label="campaign_name"
                            option-value="campaign_id"
                            clear-icon="mdi-close"
                            clearable
                            lazy-rules
                            :rules="[isRequired]"
                        />
                    </div>
                    <div class="col-12">
                        <q-select
                            v-model="params.category"
                            :label="$t('category')"
                            outlined
                            use-input
                            input-debounce="200"
                            @filter="filterCategory"
                            :options="categoriesFiltered"
                            option-label="name"
                            option-value="id"
                            clearable
                        />
                    </div>
                    <div class="col-12">
                        <q-select
                            v-model="params.bannerSize"
                            :label="$t('banner_size')"
                            outlined
                            use-input
                            input-debounce="200"
                            @filter="filterBanner"
                            :options="bannerFiltered"
                            option-label="size"
                            option-value="id"
                            clearable
                        />
                    </div>
                    <div class="col-12">
                    </div>
                    <div class="col-12 text-center">
                        <q-btn
                            color="primary"
                            :label="$t('clear')"
                            no-caps
                            outline
                            class="q-px-md q-mx-md"
                            @click="clearFilter"
                        />
                        <q-btn
                            color="positive"
                            :label="$t('search')"
                            no-caps
                            unelevated
                            class="q-px-md q-my-sm"
                            :disable="!this.channel && !this.campaignSelected"
                            type="submit"
                        />
                    </div>
                </q-card-section>
                 -->
      </q-form>
    </q-card>
  </div>
</template>

<script>
import CreativeMixin from '../../mixins/CreativeMixin';
import AdvertiserService from '../../services/AdvertiserService';
import AffiliateService from '../../services/AffiliateService';
import CreativeService from '../../services/CreativesService';
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';

export default {
  name: 'HeaderUser',
  mixins: [CreativeMixin, AdvertiserService, AffiliateService, CreativeService],
  components: { SelectRequest },
  data() {
    return {
      pageCreate: true,
      pageStart: true,
      filter: true,
      params: {
        type: null,
        category: null,
        offer: null,
        size: null,
        status: null
      },
      bannerFiltered: [],
      offerFiltered: [],
      categoriesFiltered: [],
      channelsFiltered: [],
      channelsList: [],
      categoryList: [],
      offerList: [],
      bannerList: [],

      channel: null,
      channelID: null,
      disable: true,
      campaigns: [],
      campaignsFiltered: [],
      campaignSelected: null
    };
  },
  methods: {
    filterChannel(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.channelsFiltered = this.channelsList;
        this.channelsFiltered = this.channelsList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterCampaignSelected(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaigns;
        this.campaignsFiltered = this.campaigns.filter((v) => v.campaign_name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterCategory(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.categoriesFiltered = this.categoryList;
        this.categoriesFiltered = this.categoryList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterOffer(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.offerFiltered = this.offerList;
        this.offerFiltered = this.offerList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterBanner(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.bannerFiltered = this.bannerList;
        this.bannerFiltered = this.bannerList.filter((v) => v.size.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    async getCampaignsByAffiliate() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateSiteActiveList();
        if (status === 200) this.channelsList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
           this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
         else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCategory() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getCreativeCategory();
        if (status === 200) this.categoryList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
           this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
         else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getOffer() {
      this.onLoading(true);
      try {
        const { data, status } = this.getCreativeOffer();
        if (status === 200) this.offerList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
           this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
         else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getBannerSize() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getCreativeBannersSizes();
        if (status === 200) this.bannerList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
           this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
         else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaignRelated() {
      if (this.channel) {
        this.onLoading(true);
        try {
          const { data, status } = await this.listSiteApprovedCampaigns(this.channel.id);
          if (status === 200) {
            this.disable = data.length === 0;
            this.campaigns = data;
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
           this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
         else this.errorNotify(error);
        }
      } else this.disable = true;
    },

    clearFilter() {
      for (let index in this.params) this.params[index] = null;

      this.disable = true;
      this.channel = null;
      this.campaignSelected = null;
      this.ActionSetCreativeAll();
    },

    onFilterCreative() {
      if ((this.channel !== null) !== null) {
        this.setChannelId(this.channel.id);
        let filterQuery = this.defineFilterQuery();
        this.ActionSetFilterCreative(filterQuery);
        this.getCreativeSearch();
      }
    },

    defineFilterQuery() {
      let filterSelect = [];
      filterSelect.push(`status=1`);
      filterSelect.push(`Site=${this.channel.id}`);

      if (this.campaignSelected) filterSelect.push(`Campaign=${this.campaignSelected.campaign_id}`);

      let paramsArr = Object.entries(this.params);
      paramsArr.forEach(([key, value]) => {
        if (value && Object.prototype.hasOwnProperty.call(value, 'id')) {
          filterSelect.push(`${key}=${value.id}`);
        } else if (value) {
          filterSelect.push(`${key}=${value}`);
        }
      });

      return filterSelect.join('%26');
    }
  },
  watch: {
    channel() {
      this.$refs.form.submit();
    },
    campaignSelected() {
      this.$refs.form.submit();
    },
    getCampaignRelated() {
      this.$refs.form.submit();
    },
    params: {
      handler() {
        this.$refs.form.submit();
      },
      deep: true
    }
  },
  created() {
    //this.onFilterCreative();
    this.getCampaignsByAffiliate();
    this.getCategory();
    this.getOffer();
    this.getBannerSize();
  }
};
</script>

<style></style>
