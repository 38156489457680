<template>
  <q-dialog v-model="dialog" persistent>
    <q-card flat style="min-width: 456px">
      <q-toolbar class="bg-white text-dark">
        <q-toolbar-title> Tag Afiliado </q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>

      <q-card-section v-for="(shortLink, index) in shortLinks" :key="index">
        <q-separator spaced inset />
        <q-item v-if="shortLink.shortLink.destination_url">
          <q-item-section>
            <q-item-label>
              <q-input
                outlined
                label="URL de redirecionamento"
                readonly
                type="text"
                :value="shortLink.shortLink.destination_url"
              />
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn round color="primary" @click="copyTag(shortLink.shortLink.destination_url)" icon="content_copy" />
          </q-item-section>
        </q-item>
        <q-item v-if="shortLink.shortLink.image_url">
          <q-item-section>
            <q-item-label>
              <q-input outlined label="URL da imagem" readonly type="text" :value="shortLink.shortLink.image_url" />
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn round color="primary" @click="copyTag(shortLink.shortLink.image_url)" icon="content_copy" />
          </q-item-section>
        </q-item>
        <q-item v-if="shortLink.shortLink.html">
          <q-item-section>
            <q-item-label v-if="shortLink.shortLink.html">
              <q-input readonly :value="shortLink.shortLink.html" type="textarea" label="Html" outlined>
                <template v-slot:append></template>
              </q-input>
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn round color="primary" @click="copyTag(shortLink.shortLink.html)" icon="content_copy" />
          </q-item-section>
        </q-item>
      </q-card-section>

      <q-separator spaced inset />
      <q-card-actions align="right">
        <q-btn unelevated outline no-caps label="Cancelar" class="q-px-md" color="primary" @click="onClose" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import CreativeMixin from '@/mixins/CreativeMixin';
import CreativesService from '../../services/CreativesService';
import AdvertiserService from '../../services/AdvertiserService';

export default {
  mixins: [CreativesService, CreativeMixin, AdvertiserService],
  data() {
    return {
      active: false,
      dialog: false,
      creativeData: {
        campaign_name: null,
        id: null
      },
      dialogFullScreen: false,
      maximizedToggle: true,
      options: [],
      selectedChannels: null,
      shortLinks: [],
      loading: false
    };
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    channel: {
      type: [Number, String],
      default: null
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onClose() {
      this.ActionSetCreativeSelect('');
      this.dialog = false;
      this.shortLinks = [];
      this.options = [];
      this.selectedChannels = null;
      this.$router.push({ name: 'MyCreatives' });
    },

    openFullScreen() {
      this.dialogFullScreen = !this.dialogFullScreen;
    },

    onGenerate() {
      this.onLoading(true);

      this.selectedChannels.map(this.generateTag);

      this.onLoading(false);
    },

    copyTag(item) {
      this.clickToCopy(item);
    },

    findSiteName(siteId) {
      let find = this.options.filter((x) => x.site_id == siteId);
      return find[0].site_name;
    },

    async getCreativeById(id) {
      try {
        const { data, status } = await this.getCreativeId(id);
        if (status == 200) {
          this.creativeData = data.creatives;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async generateTag() {
      this.onLoading(true);

      try {
        const { data, status } = await this.getShortnedV2(this.id, this.channelId);
        if (status === 200) {
          this.shortLinks.push({
            shortLink: data
          });
        }
      } catch (error) {
        console.log(error);
        this.errorNotify('Não foi possível gerar tags');
      } finally {
        this.onLoading(false);
      }
    }
  },
  watch: {
    id() {
      if (this.channelId !== null && this.id !== null) {
        this.dialog = true;
        this.generateTag();
      }
    }
  },
  created() {}
};
</script>

<style></style>
